import { default as activateuiKXwISJIJMeta } from "/opt/build/repo/pages/activate.vue?macro=true";
import { default as dashboardsnv2SXGj98dMeta } from "/opt/build/repo/pages/dashboards.vue?macro=true";
import { default as documentsNrvufYDaNdMeta } from "/opt/build/repo/pages/documents.vue?macro=true";
import { default as forgotUwPgolUkcoMeta } from "/opt/build/repo/pages/forgot.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as logoutlLSqGnk5PzMeta } from "/opt/build/repo/pages/logout.vue?macro=true";
import { default as organizationslkC4K1Hx64Meta } from "/opt/build/repo/pages/organizations.vue?macro=true";
import { default as performance83nFuEvU2wMeta } from "/opt/build/repo/pages/performance.vue?macro=true";
import { default as plansPed13sMCSsMeta } from "/opt/build/repo/pages/plans.vue?macro=true";
import { default as resetBBQxqLgtvHMeta } from "/opt/build/repo/pages/reset.vue?macro=true";
import { default as adminsi605p1QTHtMeta } from "/opt/build/repo/pages/settings/admins.vue?macro=true";
import { default as agregatesjD6ILlQkD4Meta } from "/opt/build/repo/pages/settings/agregates.vue?macro=true";
import { default as entitiesSwq3Y2uRTHMeta } from "/opt/build/repo/pages/settings/entities.vue?macro=true";
import { default as indexoWr0YesFbSMeta } from "/opt/build/repo/pages/settings/index.vue?macro=true";
import { default as operationsFKt8RjrYUFMeta } from "/opt/build/repo/pages/settings/operations.vue?macro=true";
import { default as organizationMhttk0AZobMeta } from "/opt/build/repo/pages/settings/organization.vue?macro=true";
import { default as planseegoKhXhS9Meta } from "/opt/build/repo/pages/settings/plans.vue?macro=true";
import { default as securitiest2ui5daCX0Meta } from "/opt/build/repo/pages/settings/securities.vue?macro=true";
import { default as shareholdersyrcVdnSPG7Meta } from "/opt/build/repo/pages/settings/shareholders.vue?macro=true";
import { default as simulationsRwbsiTXOLIMeta } from "/opt/build/repo/pages/settings/simulations.vue?macro=true";
import { default as transactionsY4y4SW13XaMeta } from "/opt/build/repo/pages/settings/transactions.vue?macro=true";
import { default as valuationsy33CC1tcAhMeta } from "/opt/build/repo/pages/settings/valuations.vue?macro=true";
import { default as settings6ZNFu1CSghMeta } from "/opt/build/repo/pages/settings.vue?macro=true";
import { default as simulationsFLngEN3bVEMeta } from "/opt/build/repo/pages/simulations.vue?macro=true";
import { default as tablesybEr28xRjoMeta } from "/opt/build/repo/pages/tables.vue?macro=true";
import { default as valuationstjhBKLnsdhMeta } from "/opt/build/repo/pages/valuations.vue?macro=true";
export default [
  {
    name: activateuiKXwISJIJMeta?.name ?? "activate",
    path: activateuiKXwISJIJMeta?.path ?? "/activate",
    meta: activateuiKXwISJIJMeta || {},
    alias: activateuiKXwISJIJMeta?.alias || [],
    redirect: activateuiKXwISJIJMeta?.redirect,
    component: () => import("/opt/build/repo/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: dashboardsnv2SXGj98dMeta?.name ?? "dashboards",
    path: dashboardsnv2SXGj98dMeta?.path ?? "/dashboards",
    meta: dashboardsnv2SXGj98dMeta || {},
    alias: dashboardsnv2SXGj98dMeta?.alias || [],
    redirect: dashboardsnv2SXGj98dMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dashboards.vue").then(m => m.default || m)
  },
  {
    name: documentsNrvufYDaNdMeta?.name ?? "documents",
    path: documentsNrvufYDaNdMeta?.path ?? "/documents",
    meta: documentsNrvufYDaNdMeta || {},
    alias: documentsNrvufYDaNdMeta?.alias || [],
    redirect: documentsNrvufYDaNdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: forgotUwPgolUkcoMeta?.name ?? "forgot",
    path: forgotUwPgolUkcoMeta?.path ?? "/forgot",
    meta: forgotUwPgolUkcoMeta || {},
    alias: forgotUwPgolUkcoMeta?.alias || [],
    redirect: forgotUwPgolUkcoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutlLSqGnk5PzMeta?.name ?? "logout",
    path: logoutlLSqGnk5PzMeta?.path ?? "/logout",
    meta: logoutlLSqGnk5PzMeta || {},
    alias: logoutlLSqGnk5PzMeta?.alias || [],
    redirect: logoutlLSqGnk5PzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: organizationslkC4K1Hx64Meta?.name ?? "organizations",
    path: organizationslkC4K1Hx64Meta?.path ?? "/organizations",
    meta: organizationslkC4K1Hx64Meta || {},
    alias: organizationslkC4K1Hx64Meta?.alias || [],
    redirect: organizationslkC4K1Hx64Meta?.redirect,
    component: () => import("/opt/build/repo/pages/organizations.vue").then(m => m.default || m)
  },
  {
    name: performance83nFuEvU2wMeta?.name ?? "performance",
    path: performance83nFuEvU2wMeta?.path ?? "/performance",
    meta: performance83nFuEvU2wMeta || {},
    alias: performance83nFuEvU2wMeta?.alias || [],
    redirect: performance83nFuEvU2wMeta?.redirect,
    component: () => import("/opt/build/repo/pages/performance.vue").then(m => m.default || m)
  },
  {
    name: plansPed13sMCSsMeta?.name ?? "plans",
    path: plansPed13sMCSsMeta?.path ?? "/plans",
    meta: plansPed13sMCSsMeta || {},
    alias: plansPed13sMCSsMeta?.alias || [],
    redirect: plansPed13sMCSsMeta?.redirect,
    component: () => import("/opt/build/repo/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: resetBBQxqLgtvHMeta?.name ?? "reset",
    path: resetBBQxqLgtvHMeta?.path ?? "/reset",
    meta: resetBBQxqLgtvHMeta || {},
    alias: resetBBQxqLgtvHMeta?.alias || [],
    redirect: resetBBQxqLgtvHMeta?.redirect,
    component: () => import("/opt/build/repo/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: settings6ZNFu1CSghMeta?.name ?? undefined,
    path: settings6ZNFu1CSghMeta?.path ?? "/settings",
    meta: settings6ZNFu1CSghMeta || {},
    alias: settings6ZNFu1CSghMeta?.alias || [],
    redirect: settings6ZNFu1CSghMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: adminsi605p1QTHtMeta?.name ?? "settings-admins",
    path: adminsi605p1QTHtMeta?.path ?? "admins",
    meta: adminsi605p1QTHtMeta || {},
    alias: adminsi605p1QTHtMeta?.alias || [],
    redirect: adminsi605p1QTHtMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/admins.vue").then(m => m.default || m)
  },
  {
    name: agregatesjD6ILlQkD4Meta?.name ?? "settings-agregates",
    path: agregatesjD6ILlQkD4Meta?.path ?? "agregates",
    meta: agregatesjD6ILlQkD4Meta || {},
    alias: agregatesjD6ILlQkD4Meta?.alias || [],
    redirect: agregatesjD6ILlQkD4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/agregates.vue").then(m => m.default || m)
  },
  {
    name: entitiesSwq3Y2uRTHMeta?.name ?? "settings-entities",
    path: entitiesSwq3Y2uRTHMeta?.path ?? "entities",
    meta: entitiesSwq3Y2uRTHMeta || {},
    alias: entitiesSwq3Y2uRTHMeta?.alias || [],
    redirect: entitiesSwq3Y2uRTHMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/entities.vue").then(m => m.default || m)
  },
  {
    name: indexoWr0YesFbSMeta?.name ?? "settings",
    path: indexoWr0YesFbSMeta?.path ?? "",
    meta: indexoWr0YesFbSMeta || {},
    alias: indexoWr0YesFbSMeta?.alias || [],
    redirect: indexoWr0YesFbSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: operationsFKt8RjrYUFMeta?.name ?? "settings-operations",
    path: operationsFKt8RjrYUFMeta?.path ?? "operations",
    meta: operationsFKt8RjrYUFMeta || {},
    alias: operationsFKt8RjrYUFMeta?.alias || [],
    redirect: operationsFKt8RjrYUFMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/operations.vue").then(m => m.default || m)
  },
  {
    name: organizationMhttk0AZobMeta?.name ?? "settings-organization",
    path: organizationMhttk0AZobMeta?.path ?? "organization",
    meta: organizationMhttk0AZobMeta || {},
    alias: organizationMhttk0AZobMeta?.alias || [],
    redirect: organizationMhttk0AZobMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: planseegoKhXhS9Meta?.name ?? "settings-plans",
    path: planseegoKhXhS9Meta?.path ?? "plans",
    meta: planseegoKhXhS9Meta || {},
    alias: planseegoKhXhS9Meta?.alias || [],
    redirect: planseegoKhXhS9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/plans.vue").then(m => m.default || m)
  },
  {
    name: securitiest2ui5daCX0Meta?.name ?? "settings-securities",
    path: securitiest2ui5daCX0Meta?.path ?? "securities",
    meta: securitiest2ui5daCX0Meta || {},
    alias: securitiest2ui5daCX0Meta?.alias || [],
    redirect: securitiest2ui5daCX0Meta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/securities.vue").then(m => m.default || m)
  },
  {
    name: shareholdersyrcVdnSPG7Meta?.name ?? "settings-shareholders",
    path: shareholdersyrcVdnSPG7Meta?.path ?? "shareholders",
    meta: shareholdersyrcVdnSPG7Meta || {},
    alias: shareholdersyrcVdnSPG7Meta?.alias || [],
    redirect: shareholdersyrcVdnSPG7Meta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/shareholders.vue").then(m => m.default || m)
  },
  {
    name: simulationsRwbsiTXOLIMeta?.name ?? "settings-simulations",
    path: simulationsRwbsiTXOLIMeta?.path ?? "simulations",
    meta: simulationsRwbsiTXOLIMeta || {},
    alias: simulationsRwbsiTXOLIMeta?.alias || [],
    redirect: simulationsRwbsiTXOLIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/simulations.vue").then(m => m.default || m)
  },
  {
    name: transactionsY4y4SW13XaMeta?.name ?? "settings-transactions",
    path: transactionsY4y4SW13XaMeta?.path ?? "transactions",
    meta: transactionsY4y4SW13XaMeta || {},
    alias: transactionsY4y4SW13XaMeta?.alias || [],
    redirect: transactionsY4y4SW13XaMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/transactions.vue").then(m => m.default || m)
  },
  {
    name: valuationsy33CC1tcAhMeta?.name ?? "settings-valuations",
    path: valuationsy33CC1tcAhMeta?.path ?? "valuations",
    meta: valuationsy33CC1tcAhMeta || {},
    alias: valuationsy33CC1tcAhMeta?.alias || [],
    redirect: valuationsy33CC1tcAhMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/valuations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: simulationsFLngEN3bVEMeta?.name ?? "simulations",
    path: simulationsFLngEN3bVEMeta?.path ?? "/simulations",
    meta: simulationsFLngEN3bVEMeta || {},
    alias: simulationsFLngEN3bVEMeta?.alias || [],
    redirect: simulationsFLngEN3bVEMeta?.redirect,
    component: () => import("/opt/build/repo/pages/simulations.vue").then(m => m.default || m)
  },
  {
    name: tablesybEr28xRjoMeta?.name ?? "tables",
    path: tablesybEr28xRjoMeta?.path ?? "/tables",
    meta: tablesybEr28xRjoMeta || {},
    alias: tablesybEr28xRjoMeta?.alias || [],
    redirect: tablesybEr28xRjoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/tables.vue").then(m => m.default || m)
  },
  {
    name: valuationstjhBKLnsdhMeta?.name ?? "valuations",
    path: valuationstjhBKLnsdhMeta?.path ?? "/valuations",
    meta: valuationstjhBKLnsdhMeta || {},
    alias: valuationstjhBKLnsdhMeta?.alias || [],
    redirect: valuationstjhBKLnsdhMeta?.redirect,
    component: () => import("/opt/build/repo/pages/valuations.vue").then(m => m.default || m)
  }
]